/* You can add global styles to this file, and also import other style files */
@import "@totalenergiescode/design-system/dist/css/totalenergies-design-system.css";
@import '@totalenergiescode/design-system/dist/css/custom-components/upload-files.css';

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

co2-root {
  height: inherit;

  co2-private-layout {
    height: inherit;
    display: flex;
    flex-direction: column;
  }
}

.card {
  padding: 0.7rem 0.7rem;
  margin-bottom: 1rem;

  .card-title {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 0.5rem;
    border-bottom: solid 1px #b7cbd3;
    display: flex;
    align-items: center;

    .card-header-icon {
      color: #ed0000;
    }
  }

  &.sub-card {
    border: solid 1px #c3c5c5;
    box-shadow: none !important;
  }
}

ngx-skeleton-loader {
  padding-top: 1rem;
}

.cfr-tooltip {
  position: relative;
  align-items: center;
  display: flex;
  margin-left: 1rem;
}

.cfr-tooltip .tooltiptext {
  font-size: 14px;
  width: 240px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  bottom: 125%;
  left: -197%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.cfr-tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;

  display: flex;
  align-items: center;
  justify-content: center;
}

/* Tooltip arrow */
.cfr-tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltiptext hr {
  width: 144px;
  margin: auto;
}
