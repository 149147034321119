@import "../design-system-color";
@import "../design-system-bootstrap/variables";


.upload-file {

  input[type="file"] {
    display: none;
  }

  ///////////////////////////////
  .upload-label {
    border: 1px dashed $grey-dark;
    border-radius: $input-border-radius;
    color: #555;
    cursor: pointer;
    display: flex;
    padding: 1.5em 0;
    justify-content: center;
    font-size: .875rem;

    .upload-format {
      font-size: .75rem;
    }
  }


  /////////////////////////////////
  .upload-label-bg {
    @extend .upload-label;
    background-color: $grey-light;
    position: relative;
    overflow: hidden;

    &::before {
      font-family: "Material Icons Outlined", sans-serif;
      content: "\E24D";
      position: absolute;
      top: -50%;
      left: -45px;
      display: block;
      font-size: 14em;
      transform: rotate(26.37deg);
      color: $grey-medium;
    }
  }

  .upload-label:hover,
  .upload-label.hover {
    border-style: solid;
  }

}

// Override background position for modals
.modal-body {
  .upload-file {
    .upload-label-bg {
      &::before {
        top: 60%;
        left: -70px;
      }
    }
  }
}

